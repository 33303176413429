.customicon{
    font-size: 23px;
    size: 400px;
    
}
.connection{
    display: flex;
    margin-top: 15px;
}

.connection:hover{
    background-color: rgb(230, 54, 54);
    padding: 2px;
}
.frecss{  
     width:100vw ;
     position: relative;
     bottom: 0px;
}