.main{
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
    align-items: center;
    justify-items: center;
    margin-top: 10px;
}
.btns{
    display: flex;
    margin-top: 10px;
    color: white;
    font-size: large;
}

.butnr {
    border: 2px solid black;
    width: 100px;
    background-color:rgb(33 146 239);
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-left: none;
    border-radius: 2px;
    width: 100%;
    padding: 5px 20px;
}

.butnl {
    border: 2px solid black;
    border-radius: 2px;
    width: 100px;
    padding: 5px 32px;
    background-color: rgb(33 146 239);
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-right: none;
}
.butnac{
    background-color: rgb(125, 193, 246);
}
.hotel-contain {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    margin-top: 20px;
}

.hotel-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 15px;
    width: 100%;
    padding: 1px 20px;
    margin-bottom: 20px;
}

.hotel-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    margin: 10px;
}

.hotel-item:hover {
    transform: scale(1.02);
}

.hotel-img {
    width: 100%;
    height: 220px;
    max-width: 220px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.hotel-name {
    font-size: 1.2em;
    color: #333;
    margin: 10px 0;
    font-weight: bold;
}

.hotel-link {
    display: flex;
    gap:4px;
    color: #2192ef;
    text-decoration: none;
    font-weight: 600;
    font-size: 0.9em;
}

.hotel-link:hover {
    text-decoration: underline;
}

.title {
    font-size: 1.5em;
    color: #333;
    margin: 10px 0;
}

.list {
    list-style-type: none;
    padding: 0;
    color: #555;
}
.card {
    background-color: #f7fafc;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 1rem auto;
  }
  
  .icon {
    background-color: #e2e8f0;
    border-radius: 50%;
    padding: 0.5rem;
    margin-right: 1rem;
  }
  
  .text-xl {
    font-size: 1.25rem;
    font-weight: 600;
  }
  
  .text-gray-700 {
    color: #4a5568;
  }
  