.themed-section {
  display: flex;
  align-items: center;
  background-color: #efefef;
  justify-content: center;
  width: 61%; /* Further reduced width */
  margin: 11px auto; /* Centered with reduced margin */
  padding: 0; /* Reduced padding */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s, opacity 0.5s;
  /* flex-direction: row; */
}

.themed-section.reverse {
  flex-direction: row-reverse;
}

.themed-section-image {
  flex: 1;
  max-width: 35%; 
  padding: 10px; 
}

.themed-section-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

.themed-section-content {
  flex: 1;
  text-align: center;
  max-width: 48%; 
  padding: 10px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px; 
}

.themed-section-content h2 {
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #333;
}

.themed-section-content p {
  line-height: 1.4;
  color: #666;
}

@media (max-width: 768px) {
  .themed-section {
    flex-direction: row;
    width: 100%;
  }

  .themed-section-image, .themed-section-content {
    max-width: 100%;
  }

  .themed-section-content {
    margin-left: 0;
    margin-top: 10px;
  }
}
