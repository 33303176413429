section {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.reg-h {
    height: 462px;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center; /* Center the content horizontally */
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    padding: 40px;
    margin-bottom: 20px;
}

.reg-h h1 {
    font-weight: bold;
    font-size: 3rem;
    text-align: center; /* Center the heading text */
}

.reg-h p {
    font-weight: bold;
    font-size: 1rem;
    text-align: center; /* Center the paragraph text */
}

.reg-sub-h {
    margin: 10px;
    font-size: 1.5rem;
    font-weight: bold;
    color: rgb(9, 9, 9);
}

.reg-p {
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-size: 1.3rem;
}

.reg-container {
    padding: 15px;
    margin: 0 30px;
    margin-top: 22px;
}

.reg-hz-line {
    border: 0.4px solid black;
}

.reg-payment-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    padding: 12px;
    background: #ffffff;
    color: rgb(0, 0, 0);
}

.payment-left {
    font-size: 1.3rem;
    padding: 25px;
}

.payment-left p {
    padding: 4px;
}

.payment-right {
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper {
    background-image: url("../../images/regis-bg.jpg");
    height: 70vh;
    max-height: 527px;
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    position: relative;
    background-size: cover;
}

.wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    filter: brightness(0.3);
    z-index: 1;
}

.wrapper > .reg-h {
    position: relative;
    z-index: 2;
}

    .respcss{
        /* width: 100vh; */
        min-width: 460px;
    }

  .price-h{
    margin: 12px;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;

}
.price-hr{
    width: 375px;
    border: 2px solid rgb(0, 0, 0);
    align-items: center;
    display: block;
    margin: auto;
    margin-bottom: 10px;
}

.price-sub-h{
    background: #59ff98;
    text-align: center;
    color: rgb(41, 39, 39);
    margin: 5px;
    width: 97%;
    display: block;
    margin: auto;
}

  #customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    margin: auto;
    margin-top: 12px;
    margin-bottom: 46px;
    /* width: 100vw; */
  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 18px;
    font-size: 1.1rem;
    text-align: center;
    
  }
  
  #customers tr:nth-child(even){background-color: #f2f2f2;}
  
  #customers tr:hover {background-color: #ddd;}
  
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #008c58;
    color: white;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
/* Notes Section  */
.reg-notes{
    margin: 35px;
}
.reg-notes h2{
    font-size: 1.7rem;
    font-weight: bold;
    margin: 4px;
    /* margin-left: 23px; */
}
.reg-notes-p{
    font-size: 1.3rem;
    padding: 3px;
}
.reg-notes-hr{
    border: 2px solid black;
    width: 140px;
}

/* Animations on Buttons */

.animate-color-load {
    /* display: inline-block; */
    padding: 0.75rem 1.25rem;
    border-radius: 10rem;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 0.15rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  
  .animate-color-load::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(7, 106, 181);;
    border-radius: 10rem;
    z-index: -2;
  }
  
  .animate-color-load::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color:  rgb(0, 60, 109);; 
    transition: all 0.3s;
    border-radius: 10rem;
    z-index: -1;
  }
  
  .animate-color-load:hover {
    color: #fff;
  }
  
  .animate-color-load:hover::before {
    width: 100%;
  }
  
  .payfee::after{
    background-color: red;
  }

  .payfee::before{
    background-color: rgb(198, 9, 9);
  }
 

@media(max-width:550px){
    .reg-payment-container{
        flex-wrap: wrap;
    }
    /* .payment-right {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom:22px ;
    } */
    /* .payment-left{
                width: 100%;
                font-size: .9rem;
                min-width: 300px;
    } */
    /* .payment-right img{
        width: 65%;
    } */
    .vl{
        height: 155px;
        top: 135px;
    }
    .price-hr{
        width: auto;
    }
}
@media (max-width: 771px) {
    /* .payment-left{
        width: 100%;
        font-size: .9rem;
        min-width: 300px;
} */
.reg-notes-p{
    font-size: 1rem;
}
#customers{
    width: auto;
}
.reg-p{
    font-size: 1rem;
}
.price-h{
    font-size: 1.5rem;
}

/* .reg-h{
    max-height: 57vh;
} */
    
    .reg-h h1{
        font-size: 2rem;
    }
    .vl{
    height: 155px;
    padding: 5px;
    position: absolute;
    top: 124px;
    left: 19px;
    }


}

@media(max-width:411px){
   .vl{
       top: 145px;
       height: 178px;}
}