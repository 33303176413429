@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
}
body{
  background: #f4f4f6;

}
a{
  text-decoration: none;
  color: inherit;
  line-height: 1;
  cursor: pointer;
}

.container{
  padding-left: 10%;
  padding-right: 10%;
}