.timelineItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    width: 200px;
    position: relative;
  }
  
  .timelineIcon {
    width: 40px;
    height: 40px;
    background-color: #4a90e2;
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    margin-bottom: 12px;
    position: relative;
  }

  /* .timelineIcon:hover{
    color: #4a90e2;
    background-color: white;
  } */
  
  .timelineIcon.firstItem::before {
    display: none;
  }
  
  .timelineContent {
    background-color: #dbeafa;
    padding: 25px;
    border-radius: 10px;
    text-align: center;   
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    min-height: 120px;
  }

  /* .timelineContent:hover{
    background-color: white;
  } */
  
  .date {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .description {
    color: #666;
    align-items: center;
  }
  