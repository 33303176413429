@media(max-width:474px){
    .respB{
        display: flex;
        flex-wrap: wrap;
    }
    .respP{
        font-size: 1.5rem;
    }
    .resph{
        font-size: 2rem;

    }
    .respCon{
        margin-top: -56px;
    }
}
.respCon{
  margin-top: -10rem;
}
@media (max-width:619px) {
    .respAbt{
        display: flex;
        flex-direction: column;
    }
}

.hecss{
    /* margin-left: auto; */
    min-width: 460px;
    /* opacity: ; */
    /* padding-left: 10px; */
    /* padding-right: 10px; */
}

.animate-color-load {
    /* display: inline-block; */
    padding: 0.75rem 1.25rem;
    border-radius: 10rem;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 0.15rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  
  .animate-color-load::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(67 67 255 / 85%);
    border-radius: 10rem;
    z-index: -2;
  }
  
  .animate-color-load::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color:  rgb(0, 60, 109);; 
    transition: all 0.3s;
    border-radius: 10rem;
    z-index: -1;
  }
  
  .animate-color-load:hover {
    color: #fff;
  }
  
  .animate-color-load:hover::before {
    width: 100%;
  }