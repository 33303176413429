.allbdy{
    margin-top: 2.5rem;
    margin-bottom: 2rem;
}

.headinghd{
    font-size: 2.5rem;
    text-align: center;
    text-decoration: none;
    color: rgb(40, 109, 221);
}

.tablecs {
    margin-top: 1rem;
    width: 60vw;
}

td {
    border: 2px solid white;
    margin: 2px;
    padding: 8px;
    text-align: center;
}

.oddrow{
    background-color: rgb(149, 149, 239);
}

.evenrow{
    background-color: rgb(145, 238, 238);
}

.navcolor{
    background-color:  rgb(24, 37, 78);
    height: 65px;
}
.oddrow:hover,.evenrow:hover{
    color: white;
    transition: text-transform 0.2ms ease-in-out;
    transform: scale(1.1);
}

.themes-container{
    display: grid;
    margin: auto;
    margin-top: 10rem;
    align-items: center;
    justify-content: center;
    width: 75vw;
    min-width: 460px;
    justify-items: center;
}