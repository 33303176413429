@tailwind base;
@tailwind components;
@tailwind utilities;



.bgImg{
    background :linear-gradient(rgb(19 56 105), rgb(71 64 98 / 70%));; 
    /* opacity: ; */
    background-size: cover;
    background-position: center;
    color : #fff;
}