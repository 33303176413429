.cardscontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px;
  background-color: #f5f5f5; /* Subtle background color */
}

.profile-card {
  position: relative;
  width: 280px;
  margin: 20px;
  padding: 30px;
  background: linear-gradient(145deg, #ffffff, #e6e6e6); /* Soft gradient background */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
  text-align: center;
  transition: all 0.3s ease;
}

.profile-card:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px); /* Slight lift on hover */
}

.header {
  margin-bottom: 20px;
}

.profile-title {
  font-size: 22px;
  font-weight: bold;
  color: #333;
}

.profile-subtitle {
  font-size: 16px;
  color: #777;
  margin-bottom: 10px;
}

.profile-bio {
  font-size: 14px;
  color: #555;
  line-height: 1.6;
  margin-top: 15px;
}

.mask-shadow {
  z-index: -1 !important;
  width: 95%;
  height: 12px;
  background: rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  border-radius: 10px;
  opacity: 0;
  transition: all 0.4s ease-in;
}

.profile-card:hover .mask-shadow {
  opacity: 1;
  box-shadow: 0px 20px 40px -5px rgba(55, 55, 71, 0.2);
}
