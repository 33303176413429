.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 15%;
  }
  
  .Cmembers {
    font-family: Arial, Helvetica, sans-serif;
    margin: 22px 0;
    width: 100%;
    max-width: 1200px;
    border-collapse: collapse;
    box-sizing: border-box;
    table-layout: fixed; /* Ensures equal column widths */
  }
  
  .Ctd, .Cth {
    border: 1px solid #ddd;
    padding: 18px;
    text-align: center;
    vertical-align: middle;
    transition: padding 0.3s ease, font-size 0.3s ease;
    word-wrap: break-word; /* Ensures content wraps within cells */
  }
  
  .Cth {
    background-color: #009860;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .Ctr {
    background-color: #ffffdb;
    transition: transform 0.3s ease;
    border: 2px solid rgb(111, 185, 111);
  }
  
  .Ctr:hover {
    background-color: #ffffbc;
    transform: scale(1.05);
    border: 2px solid rgb(8, 9, 8);
  }
  
  .det {
    text-align: center;
    margin-top: 10px;
    font-size: 0.9rem;
    color: #474444;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .Ctd, .Cth {
      padding: 10px;
      font-size: 0.9rem;
    }
  
    .Cth {
      font-size: 1rem;
    }
  
    .det {
      font-size: 0.8rem;
    }
  }
  
  @media (max-width: 480px) {
    .Ctd, .Cth {
      padding: 8px;
      font-size: 0.8rem;
    }
  
    .Cth {
      font-size: 0.9rem;
    }
  
    .det {
      font-size: 0.7rem;
    }
  
    .container {
      padding: 10px;
    }
  
    .Cmembers {
      margin: 0;
    }
  }
  