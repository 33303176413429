.main{
  margin:auto;  
  margin-top: 3rem;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
  background: size(50,50); 
  overflow: hidden;
  min-width: 460px;
  position: relative;
}
.imgset{
  object-fit: contain;
  /* aspect-ratio: ; */
}