.container{
    display: flex;
    /* margin: 22px; */
    /* flex-direction: column; */
    padding-top: 60px;
    width: 100vw;
    /* min-width: 460px; */
}
.heading{
    /* margin: 15px; */
    /* padding: 12px; */
    font-size: 1.5rem;
    position: absolute;
    /* right: 121px;
    
    left: 86px;
    top: 64px; */
    
}

.heading {
    display: flex;
    width: 100%;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 400;
    align-items: center;
    text-align: center;
    /* text-decoration: underline; */
}

/* .heading:before,
.heading:after {
    content: '';
    border-top: 2px solid;
    margin: 0 10px 0 10px;
    flex: 1 0 20px;
}

.heading:after {
    margin: 0 0 0 20px;
} */
