.textstrip-container {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
}

.submit, .brochure, .registercss {
    cursor: pointer;
    margin: 0 10px;
    animation: colorChange 1s infinite; 
}

@keyframes colorChange {
    0% { color: #FF5733; }
    25% { color: #33FF57; }
    50% { color: #3357FF; }
    75% { color: #FF33A8; }
    100% { color: #FF5733; }
}

@media (max-width: 768px) {
    .submit, .brochure, .registercss {
        font-size: 2vw;
    }
}

@media (max-width: 480px) {
    .submit, .brochure, .registercss {
        font-size: 3vw;
    }
    .textstrip-container {
        height: auto;
        padding: 0 10px;
    }
}
